import React from 'react'
import styled from 'styled-components'


const Container = styled.div`
    background-color: rgba(251, 253, 255, 0.16);
    display: flex;
    padding: 10px 20px;
    justify-content: space-between;
    border-radius: 50px;

    input {
        background-color: transparent;
        border: none;
        outline: none;
        color: #ffffff; 
        width: 300px;

        ::placeholder {
            font-weight: 500;
            font-size: 12px;
            line-height: 132%;
            letter-spacing: -0.005em;
            color: #ffffff;
        }
    }

    .search-input-glass-icon {
        border-left: 1px solid rgba(255, 255, 255, 0.4);
        padding: 0 10px;
    }
`;

const SearchGlassInput = ({ placeholder, onChange }) => {
    return (
        <Container>
            <input placeholder={placeholder} onChange={onChange} />
            <div className="search-input-glass-icon">
                <img src={require("../../assets/svgs/search-icon.svg")} alt="search-icon" />
            </div>
        </Container>
    )
}

export default SearchGlassInput

import React from 'react'
import InfoCard from './InfoCard';

const PersonalDetails = () => {
    return (
        <div style={{marginBottom: 40 }}>
            <InfoCard title="BIO" />
        </div>
    )
}

export default PersonalDetails

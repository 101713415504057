import styled from 'styled-components'


//SearchRez
export const Outer = styled.div`
    padding: 40px 80px;
    
`
export const Inner = styled.div`
    padding: 0;
`
export const Header = styled.div`
    display: flex;
    background: #f9fafe;
    border-radius: 10px 10px 0 0;
    padding: 20px 40px;
`;
export const Title = styled.div`
    font-family: Roboto Slab;
    font-size: 20px;
    line-height: 132%;
    letter-spacing: 0.145em;
    text-transform: uppercase;
    color: #393939;
`;
export const SearchContainer = styled.div`
    margin: 0;
`
export const SearchList = styled.div`
    min-height: 400px;
`
export const PaginationContainer = styled.div`
    margin: 0;
`

//

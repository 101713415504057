import React from 'react'
import styled from 'styled-components'


const Container = styled.div`
    width: 180px;
    padding: 15px 20px;
    background: #0d8ec7;
    color: #fff;
    border-radius: 4px;
    width: ${ props => props.wd };
    display: flex;
    justify-content: space-between;
    cursor: pointer;
`;

const ReminderBtn = ({ text, wd }) => {
    return (
        <Container wd={wd}>
            <img src={require("../../assets/svgs/bell.svg")} alt="bell reminder icon" />
            <div>{text}</div>
        </Container>
    );
}

export default ReminderBtn

export default [
    {
        icon: require("../../assets/svgs/services/correct.svg"),
        service: "Information Validation",
        bg: require("../../assets/images/service-bg/testbg.png")
    },
    {
        icon: require("../../assets/svgs/services/correct.svg"),
        service: "Information Validation",
        bg: require("../../assets/images/service-bg/testbg.png")
    },
    {
        icon: require("../../assets/svgs/services/correct.svg"),
        service: "Information Validation",
        bg: require("../../assets/images/service-bg/testbg.png")
    },
    {
        icon: require("../../assets/svgs/services/correct.svg"),
        service: "Information Validation",
        bg: require("../../assets/images/service-bg/testbg.png")
    },
    {
        icon: require("../../assets/svgs/services/correct.svg"),
        service: "Information Validation",
        bg: require("../../assets/images/service-bg/testbg.png")
    }
];
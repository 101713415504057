import React from "react";

function Invite({ color }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 19">
            <g fill={color} fillOpacity="0.51" clipPath="url(#clip0)">
                <path d="M4.748 1.744h1.728c.238 0 .431.194.431.432v2.59a.432.432 0 01-.431.433H4.748a.432.432 0 01-.432-.432V2.176c0-.238.194-.432.432-.432zM12.522 1.744h1.728c.238 0 .431.193.431.432v2.59a.432.432 0 01-.431.433h-1.728a.432.432 0 01-.431-.432V2.176c0-.239.193-.432.431-.432zM6.48 12.107H3.889a.432.432 0 00-.432.432v1.296h3.455v-1.296a.432.432 0 00-.432-.432z" />
                <path d="M17.704 3.472h-2.159v1.295c0 .715-.58 1.295-1.295 1.296h-1.727c-.715-.001-1.295-.58-1.296-1.296V3.472H7.773v1.295c-.001.715-.58 1.295-1.296 1.296H4.75c-.715-.001-1.295-.58-1.295-1.296V3.472h-2.16C.58 3.472.001 4.052 0 4.767v11.227c0 .715.58 1.295 1.295 1.296h16.41c.715-.001 1.294-.58 1.295-1.296V4.767c0-.715-.58-1.295-1.296-1.295zM7.774 14.267a.432.432 0 01-.432.432H3.023a.432.432 0 01-.432-.432V12.54c.002-.57.377-1.073.925-1.235a2.137 2.137 0 01-.493-1.356 2.159 2.159 0 114.318 0 2.138 2.138 0 01-.493 1.356c.547.162.923.664.925 1.235v1.727zm8.204-.432H9.932a.432.432 0 010-.863h6.045a.432.432 0 110 .863zm0-2.159H9.932a.432.432 0 010-.864h6.045a.432.432 0 110 .864zm0-2.159H9.932a.432.432 0 010-.864h6.045a.432.432 0 110 .864z" />
                <path d="M6.478 9.95a1.296 1.296 0 11-2.591 0 1.296 1.296 0 012.59 0z" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <path fill="#fff" d="M0 0H19V19H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

Invite.defaultProps = {
    color: "#717171"
}

export default Invite;

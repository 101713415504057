import React from 'react'
import styled from 'styled-components'


const Container = styled.div`
    width: 100px;
    margin: 20px;
    padding-left: 40px;
    padding-top: 40px;

    .footer-link-title {
        font-family: Roboto Slab;
        font-size: 18px;
        line-height: 24px;
        text-transform: uppercase;
        color: #ffffff;
    }
`;
const Links = styled.div`
    margin: 0;
    color: #fff;
    a {
        display: block;
    }
`

const LinkSection = ({ title, children }) => {
    return (
        <Container>
            <div className="footer-link-title">{title}</div>
            <Links>{children}</Links>
        </Container>
    )
}

export default LinkSection

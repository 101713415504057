import styled from 'styled-components'

export const Container = styled.div`
    margin-bottom: ${ props => props.mb };
`
export const Top = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 60px 40px 40px;

    .arrows-container {
        display: flex;
    }
`
export const Title = styled.div`
    max-width: 760px;
`
export const SlideBtn = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.06);
    border-radius: 50%;
    margin: 0 10px;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 24px;
    cursor: pointer;
`   
export const Slides = styled.div`
    display: flex;
    padding-left: 40px;
`
import React from 'react'
import styled from 'styled-components'
import Button from './Buttons/Button';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 10px;
    z-index: 5;
    position: relative;

    .navlink {
        padding: 10px 20px;
        color: #fff;
        margin-right: 10px;
        cursor: pointer;
    }
`;
const Left = styled.div`
    padding: 0;
`
const Right = styled.div`
    display: flex;
`

const Navigation = ({ signup, login }) => {
    return (
        <Container>
            <Left>
                <div className="navlink">
                    <img
                        src={require("../assets/svgs/logo.svg")}
                        alt="home button logo"
                    />
                </div>
            </Left>
            <Right>
                <div onClick={login} className="navlink">
                    Log In
                </div>
                <div>
                    <Button onClick={signup} alt>
                        Sign Up
                    </Button>
                </div>
            </Right>
        </Container>
    );
}

export default Navigation

import React, { Component } from "react";
import Card from "../../Card";
import * as UI from "./org-styles";
import InviteCard from "./InviteCard/InviteCard";
import PaginationNav from "./Pagination";

export default class SearchRez extends Component {
    state = {
        page: 0
    };
    render() {
        const { page } = this.state;
        return (
            <Card>
                <UI.Inner>
                    <UI.Header>
                        <UI.Title>Invitations</UI.Title>
                        <UI.SearchContainer></UI.SearchContainer>
                    </UI.Header>
                    <UI.SearchList>
                        <InviteCard />
                        <InviteCard />
                    </UI.SearchList>
                    <UI.PaginationContainer>
                        <PaginationNav current={page} />
                    </UI.PaginationContainer>
                </UI.Inner>
            </Card>
        );
    }
}

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

/**
 * @param {string} icon The Url of the icon to be shown on profile. Is required
 */

const Container = styled.div`
    display: flex;

    .nav-user-info {
        padding-left: 20px;
        padding-right: 40px;
    }
`;
const Name = styled.div`
    font-family: Roboto Slab;
    font-weight: bold;
    font-size: 14px;
    line-height: 132%;
    color: #696969;
    margin-bottom: 10px;
`;
const Career = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 132%;
    letter-spacing: -0.005em;
    color: rgba(137, 137, 137, 0.83);
`;
const IMG = styled.img`
    box-shadow: 0px 8px 20px rgba(15, 67, 96, 0.25);
    border-radius: 50%;
    height: 40px;
`;

const UserNavInfo = ({ icon, name, career }) => {
    return (
        <Container>
            <IMG src={icon} alt="profile pic" />
            <div className="nav-user-info">
                <Name className="nav-user-info-name">{name}</Name>
                <Career className="nav-user-info-career">{career}</Career>
            </div>
        </Container>
    );
};

UserNavInfo.propTypes = {
    icon: PropTypes.string.isRequired
};
UserNavInfo.defaultProps = {
    icon: require("../../assets/images/home reviews/profilepic.png")
};

export default UserNavInfo;

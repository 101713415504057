import React from "react";

function Organizations({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 21 21"
        >
            <path
                fill={color}
                fillOpacity="0.51"
                d="M5.728 1.704H.73a.73.73 0 00-.729.73v16.133c0 .402.326.729.73.729h4.998a.73.73 0 00.73-.73V2.434a.73.73 0 00-.73-.729zM1.522 3.797a.73.73 0 01.73-.73h1.954a.73.73 0 01.729.73v6.908c0 .402-.326.729-.73.729H2.253a.729.729 0 01-.73-.73V3.798zM3.23 16.7a1.73 1.73 0 01-1.73-1.73 1.73 1.73 0 011.73-1.728 1.73 1.73 0 011.729 1.729 1.73 1.73 0 01-1.73 1.729zm9.77-14.997H8.002a.729.729 0 00-.73.73v16.133c0 .402.327.729.73.729h4.998a.73.73 0 00.73-.73V2.434a.73.73 0 00-.73-.729zM8.794 3.797a.73.73 0 01.729-.73h1.954a.73.73 0 01.73.73v6.908c0 .402-.327.729-.73.729H9.523a.729.729 0 01-.73-.73V3.798zM10.5 16.7a1.731 1.731 0 01-1.729-1.73 1.73 1.73 0 011.729-1.728 1.73 1.73 0 011.729 1.729A1.73 1.73 0 0110.5 16.7zm9.77-14.997h-4.998a.73.73 0 00-.73.73v16.133c0 .402.327.729.73.729h4.999a.73.73 0 00.729-.73V2.434a.73.73 0 00-.73-.729zm-4.205 2.093a.73.73 0 01.73-.73h1.953a.73.73 0 01.73.73v6.908c0 .402-.327.729-.73.729h-1.954a.729.729 0 01-.729-.73V3.798zM17.771 16.7a1.731 1.731 0 01-1.729-1.73 1.73 1.73 0 011.73-1.728 1.73 1.73 0 011.728 1.729 1.73 1.73 0 01-1.729 1.729z"
            />
        </svg>
    );
}

Organizations.defaultProps = {
    color: "#717171"
}

export default Organizations;

import React from 'react'
import styled from 'styled-components'


const Container = styled.div`
    padding: 40px;
    cursor: pointer;
`
const Main = styled.div`
    display: flex;
    justify-content: space-around;
`
const Icon = styled.div`
    margin: 0;
    width: 15px;
    margin-right: 20px;
`
const Location = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    text-transform: capitalize;

    color: #717171;
`;
const Bline = styled.div`
    position: relative;
`
const BottomLine = styled.div`
    position: absolute;
    border-radius: 18px;
    height: 4px;
    width: 100%;
    bottom: -10px;
    background: #0d8dc6;
`;

const ProfileNavBtn = ({ current, location, children }) => {
    return (
        <Container>
            <Main>
                <Icon>{children}</Icon>
                <Location>{location}</Location>
            </Main>
            <Bline>
                <BottomLine />
            </Bline>
        </Container>
    );
}

export default ProfileNavBtn

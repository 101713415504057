import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.button`
    display: flex;
    justify-content: space-between;
    padding: ${ props => props.pad };
    width: ${ props => props.width};
    background: linear-gradient(90deg, #0eb3e4 2.49%, #0e9cd2 100%);
    box-shadow: 0px 20px 40px rgba(13, 57, 120, 0.21);
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    border: none;
    outline: none;

    .arrow-btn-left {
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.1em;
        padding-left: 10px;
        font-size: 14px;
        line-height: 148.5%;
    }
`;
Container.defaultProps = {
    width: "280px",
    pad: "15px 30px"
};


const ArrowBtn = ({ pad, width, onClick, children}) => {
    return (
        <Container width={width} pad={pad} onClick={onClick} >
            <div className="arrow-btn-left">{children}</div>
            <div className="arrow-btn-right">→</div>
        </Container>
    );
}

ArrowBtn.propTypes = {
    pad: PropTypes.string,
    width: PropTypes.string
}

export default ArrowBtn

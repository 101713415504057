import styled from 'styled-components'


export const Container = styled.div`
    width: 600px;
    max-width: 80%;
    margin: 0 auto;
    padding: 60px 20px 0;

    .reset-pass-input-spacer {
        margin-bottom: 20px;
    }
`;
export const ButtonContainer = styled.div`
    width: 300px;
    max-width: 40%;
    padding-top: 20px;
`;

export const Title = styled.div`
    font-size: 32px;
    margin-bottom: 20px;
`
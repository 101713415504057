import React from 'react'
import Card from '../../Card';
import Metrics from '../Metrics';
import IconUserInfo from '../IconUserInfo';
import * as UI from './head-styles'




const ProfileHead = ({ name, aspects, metrics }) => {
    return (
        <UI.Container>
            <UI.CardContainer>
                <Card>
                    <UI.ProfileHeadContainer>
                        <UI.ProfileLeft>
                            <UI.ProfilePic>
                                <img
                                    src={require("../../../assets/images/profilepic.png")}
                                    alt="profile avatar"
                                />
                            </UI.ProfilePic>
                            <UI.InfoSection>
                                <div className="profile-head-name">{name}</div>
                                <div className="profile-head-email-phone">
                                    <IconUserInfo
                                        icon={require("../../../assets/svgs/profilemail.svg")}
                                        data="henderson@gmail.com"
                                    />
                                    <IconUserInfo
                                        icon={require("../../../assets/svgs/profilephone.svg")}
                                        data="(443) 377-818-83"
                                    />
                                </div>
                                <div className="profile-head-aspects">
                                    {aspects.map((aspect, i) => (
                                        <UI.Aspect key={i}>{aspect}</UI.Aspect>
                                    ))}
                                </div>
                                <div className="profile-head-btns">
                                    <UI.ProfileBtn>
                                        <img
                                            src={require("../../../assets/svgs/profilebtnview.svg")}
                                            alt="view icon"
                                        />
                                        <span>View Public Profile</span>
                                    </UI.ProfileBtn>
                                    <UI.ProfileBtn>
                                        <img
                                            src={require("../../../assets/svgs/profilebtnedit.svg")}
                                            alt="edit icon"
                                        />
                                        <span>View Public Profile</span>
                                    </UI.ProfileBtn>
                                </div>
                            </UI.InfoSection>
                        </UI.ProfileLeft>
                        <UI.ProfileRight>
                            <Metrics />
                        </UI.ProfileRight>
                    </UI.ProfileHeadContainer>
                </Card>
            </UI.CardContainer>
        </UI.Container>
    );
}

ProfileHead.defaultProps = {
    name: "Joel Henderson",
    aspects: ["Camera Operator", "Photographer", "Director of Photography"]
}

export default ProfileHead

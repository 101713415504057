import React from 'react'
import * as UI from './styles' 
import Contact from './Contact';
import LinkSection from './LinkSection';



const Footer = () => {
    return (
        <UI.Container>
            <Contact />
            <UI.Links>
                <div className="footer-links">
                    <LinkSection title="employer candidate">
                        <UI.FakeLink>xxx</UI.FakeLink>
                        <UI.FakeLink>xxx</UI.FakeLink>
                        <UI.FakeLink>xxx</UI.FakeLink>
                        <UI.FakeLink>xxx</UI.FakeLink>
                    </LinkSection>
                    <LinkSection title="employer candidate">
                        <UI.FakeLink>xxx</UI.FakeLink>
                        <UI.FakeLink>xxx</UI.FakeLink>
                        <UI.FakeLink>xxx</UI.FakeLink>
                        <UI.FakeLink>xxx</UI.FakeLink>
                    </LinkSection>
                    <LinkSection title="employer candidate">
                        <UI.FakeLink>xxx</UI.FakeLink>
                        <UI.FakeLink>xxx</UI.FakeLink>
                        <UI.FakeLink>xxx</UI.FakeLink>
                        <UI.FakeLink>xxx</UI.FakeLink>
                    </LinkSection>
                </div>
                <div className="footer-bottom">
                    <div className="footer-bottom-left">
                        <img
                            src={require("../../assets/svgs/logo.svg")}
                            alt="footer logo"
                        />
                    </div>
                    <div className="footer-bottom-left">@dossr.io</div>
                </div>
            </UI.Links>
        </UI.Container>
    );
}

export default Footer

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import BaseUser from '../Profile/BaseUser';


const Container = styled.div`
    width: 300px;
    padding: 20px;
    cursor: pointer;
    
    :hover {
        background: #ffffff;
        box-shadow: 0px 16px 100px rgba(131, 131, 131, 0.25);
        border-radius: 4px;
    }
`;
const Stars = styled.div`
    display: flex;
    margin-bottom: 10px;
    .customer-rating-stars {
        color: #f2b01e;
        margin: 5px;
    }
`;
const Comment = styled.div`
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 146%;
    letter-spacing: 0.02em;
    color: #838383;
`;
const CustomerRating = ({ stars, comment, user }) => {
    return (
        <Container>
            <Stars>
                {[...Array(stars)].map((e, i) => (
                    <span className="customer-rating-stars" key={i}>
                        ★
                    </span>
                ))}
            </Stars>
            <Comment>{comment}</Comment>
            <BaseUser
                icon={user.icon}
                name={user.name}
                career={user.career}
            />
        </Container>
    );
}

CustomerRating.propTypes = {
    stars: PropTypes.number,
    comment: PropTypes.string,
    user: PropTypes.object,
}


export default CustomerRating

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'


const Container = styled.div`
    border-bottom: 1px solid
        ${props =>
            props.primary
                ? "rgba(255, 255, 255, 0.7)"
                : "rgba(90, 90, 90, 0.68)"};
    width: ${ props => props.width};
`;
Container.defaultProps = {
    width: "80%"
}
const InputTitle = styled.div`
    font-size: 12px;
    color: ${props =>
        props.primary
            ? "rgba(255, 255, 255, 0.7)"
            : "rgba(90, 90, 90, 0.68)"};
`;
const FormInput = styled.input`
    outline: none;
    border: none;
    background: transparent;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0;
    color: ${props => (props.primary ? "#fff" : "rgba(90, 90, 90, 0.68)")};
    width: 100%;
`;

const Input = ({ name, type, title, width, primary, onChange, ...props }) => {
    return (
        <Container width="100%" primary={primary}>
            <InputTitle primary={primary} >{title}</InputTitle>
            <FormInput primary={primary} onChange={onChange} type={type} name={name} autoComplete="off" {...props} />
        </Container>
    )
}

Input.defaultProps = {
    primary: true,
    type: "text"
}
Input.propTypes = {
    primary: PropTypes.bool,
    type: PropTypes.string
}

export default Input

import React, { Component } from 'react'
import Input from '../../Input';
import Button from '../../../Buttons/Button';
import { Container, ButtonContainer, Title } from './styles'

export default class SendResetLink extends Component {
    state = {
        form: {
            login: ''
        },
        status: {
            loading: false,
            error: null
        }
    }

    handleChange = e => {
        this.setState({
            form: { 
                ...this.state.form,
                [e.target.name]: e.target.value 
            }
         })
    }

    handleSubmit = e => {
        e.preventDefault()
        console.log(this.state.form);
    }

    render() {
        return (
            <Container>
                <Title>Send Reset Password Link</Title>
                <form onSubmit={this.handleSubmit}>
                    <div className="reset-pass-input-spacer">
                        <Input title="Username" name="login" primary={ false } onChange={this.handleChange} />
                    </div>
                    <ButtonContainer>
                        <Button>Send Link</Button>
                    </ButtonContainer>
                </form>
            </Container>
        )
    }
}

import React from 'react'
import * as Form from './styles'
import Individual from './Individual';
import Business from './Business';
import { Container } from '../Login/style'
import Tab from './Tab';

class RegistrationForm extends React.Component {
    state = {
        form: 0
    };

    handleIndividualClick = e => {
        this.setState({ form: 1 });
    };

    handleBusinessClick = e => {
        this.setState({ form: 0 });
    };

    render() {
        const { form } = this.state;
        const RenderedForm = form === 0 ?  Business : Individual;
        return (
            <Container>
                <Form.Nav>
                    <Tab
                        header="for Business"
                        main="Employment Screening"
                        onClick={this.handleBusinessClick}
                        active={form === 0}
                    />
                    <Tab
                        header="for Individuals"
                        main="Personal Credential Verification"
                        onClick={this.handleIndividualClick}
                        active={ form === 1 }
                    />
                </Form.Nav>
                <Form.Rendered>
                    <RenderedForm />
                </Form.Rendered>
            </Container>
        );
    }
}


export default RegistrationForm

import styled from 'styled-components'

export const H2 = styled.div`
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: normal;
    font-size: ${ props => props.fz };
    line-height: 66px;
    color: #1D2047;
`
H2.defaultProps = {
    fz: "42px"
};

export const H3 = styled.div`
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 34px;
    text-align: center;
    color: #1D2047;
`

export const YH = styled.div`
    font-size: 18px;
    line-height: 25px;
    text-transform: uppercase;
    letter-spacing: 0.19em;

    color: #F6B421;
`
export const SubTxt = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 153.77%;
    /* or 28px */

    color: #8e8d8d;
`;
export const FormHeaderTitle = styled.div`
    font-family: Roboto Slab;
    font-size: 36px;
    line-height: 47px;
    color: ${ props => props.color };
`;
FormHeaderTitle.defaultProps = {
    color: "#fff"
}
import React, { Component } from 'react'
import * as UI from './styles'
import { H2 } from '../Typography';

/** This a carousel component used for sections on the landing page.*/
export default class Slider extends Component {

    render() {
        const { title, mb, children} = this.props
        return (
            <UI.Container mb={mb} >
                <UI.Top>
                    <UI.Title>
                        <H2> {title} </H2>
                    </UI.Title>
                    <div className="arrows-container" >
                        <UI.SlideBtn>←</UI.SlideBtn>
                        <UI.SlideBtn>→</UI.SlideBtn>
                    </div>
                </UI.Top>
                <UI.Slides>{ children }</UI.Slides>
            </UI.Container>
        );
    }
}

import React, { Component } from 'react'
import PersonalDetails from './PersonalDetails';
import * as UI from '../profile-styles'
import ProfileNav from '../ProfileNav';



export default class Profile extends Component {
    render() {
        return (
            <UI.Container>
                <ProfileNav />
                <PersonalDetails />
                <PersonalDetails />
            </UI.Container>
        );
    }
}

import React from 'react'
import * as UI from './styles'
import InfoPanel from '../../InfoPanel';

const Education = () => {
    return (
        <UI.Container>
            <div className="edu-section left">
                <UI.BGIMG />
            </div>
            <div className="edu-section right">
                <InfoPanel
                    section="Education"
                    title="Credential Verification with a difference."
                    subText="We’re driven by speed, efficiency and quality and backed by one of the most experienced teams in the industry. "
                    btnText="Our Blog"
                />
            </div>
        </UI.Container>
    );
}

export default Education

import React from 'react'
import styled from 'styled-components'


const Container = styled.div`
    display: ${props => (props.show ? "block" : "none")};
    position: relative;
`;
const Backdrop = styled.div`
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    z-index: 10;
`;
const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 600px;
    left: 0px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1040px;
    width: calc(100% - 100px);
    position: fixed;
    right: 0px;
    top: 10%;
    z-index: 510;
`;

const Modal = ({ show, onClick, children }) => {
    return (
        <Container show={show}>
            <FormContainer>
                {children}
            </FormContainer>
            <Backdrop onClick={onClick} />
        </Container>
    )
}

export default Modal

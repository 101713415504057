import React from "react";
import * as UI from "./head-styles";
import OrgCard from "../OrgProfile/OrgCard";
import SearchGlassInput from "../../Forms/SearchGlassInput";



const OrgProfileHead = ({ name, aspects, metrics }) => {
    return (
        <UI.Container ht="300px">
            <UI.SearchArea>
                <UI.SearchAreaLeft>Organization Overview</UI.SearchAreaLeft>
                <UI.SearchAreaRight>
                    <SearchGlassInput placeholder="Find Reference" />
                </UI.SearchAreaRight>
            </UI.SearchArea>
            <UI.OrgCardContainer>
                <OrgCard
                    value="45"
                    iconBG="rgba(52, 214, 130, 0.09)"
                    title="Completed Checks"
                />
                <OrgCard
                    value="20"
                    iconBG=" rgba(250, 169, 73, 0.15)"
                    title="Awaiting Info"
                    icon={require("../../../assets/svgs/hourglass.svg")}
                />
                <OrgCard
                    value="15"
                    iconBG="rgba(204, 52, 134, 0.06)"
                    title="Missing Info"
                    icon={require("../../../assets/svgs/document.svg")}
                />
                <OrgCard
                    value="30"
                    iconBG="rgba(14, 179, 228, 0.11)"
                    title="Invites"
                    icon={require("../../../assets/svgs/chronometer.svg")}
                />
            </UI.OrgCardContainer>
        </UI.Container>
    );
};


export default OrgProfileHead;

import React from 'react'
import styled from 'styled-components'


const Container = styled.div`
    height: ${ props => props.ht };
    width: auto;
    text-align: center;
    margin: auto 0;
`

const Notify = styled.div`
    position: relative;
    display: ${ props => props.show ? 'block' : 'none' };
    `
const Icon = styled.img`
    position: absolute;
    top: 10px;
    right: -3px;
`

const Notifier = ({ notifications, children }) => {
    return (
        <Container>
            <Notify show={notifications}>
                <Icon
                    src={require("../assets/svgs/notify.svg")}
                    alt="notification icon"
                />
            </Notify>
            {children}
        </Container>
    );
}

export default Notifier

import React from 'react'
import styled from 'styled-components'
import { YH } from '../Typography';
import Button from '../Buttons/Button';


const Container = styled.div`
    padding: 20px 100px 60px;
    display: flex;
    justify-content: space-between;

    .title {
        margin-bottom: 20px;
    }
    .left {
        .email-section {
            display: flex;

            .envelope-icon{
                margin-right: 40px;
            }
        }
    }
    .right {
        padding-top: 40px;
    }
`
const Email = styled.div`
    font-family: Roboto Slab;
    font-size: 30px;
    line-height: 47px;
    color: #1d2047;
`;

const Contact = () => {
    return (
        <Container>
            <div className="contact left">
                <YH className="contact title">Contact</YH>
                <div className="email-section">
                    <img
                        className="envelope-icon"
                        src={require("../../assets/svgs/envelope.svg")}
                        alt="envelope icon"
                    />
                    <Email>info@dossr.io</Email>
                </div>
            </div>
            <div className="contact right">
                <Button fz="24px" pad="10px 40px"> CONTACT </Button>
            </div>
        </Container>
    );
}

export default Contact

import types from '../../types/authentication'

const { USER_LOGIN } = types

const initialState = {
    user: {
        status: "GUEST",
        details: {}
    }
}
export default (state = initialState, action) => {
    switch (action.type) {
        case USER_LOGIN:
            return {
                user: { 
                    status: "SIGNED_IN",
                    details: {...action.user}
                }
            };
        default:
            return state;
    }
};

import React from "react";
import styled from "styled-components";
import PropTypes from 'prop-types'


const Container = styled.div`
    margin: 0;
`;
const Inner = styled.div`
    display: flex;
    margin-bottom: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
    padding-bottom: 30px;
`;
const Btn = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 132%;
    text-transform: uppercase;
    letter-spacing: 0.145em;
    margin-right: 60px;

    color: ${props => (props.chosen ? "#545454" : "#bbbbbb")};
`;

const ProfileNav = ({ pages, current, onClick }) => {
    const safePages = ( pages.length === 0) ? ["Personal Details", "Experience", "Education"] : pages
    return (
        <Container>
            <Inner>
                {safePages.map((page, i) => (
                    <Btn chosen={page === current} onClick={onClick} key={i}>
                        {page}
                    </Btn>
                ))}
            </Inner>
        </Container>
    );
};

ProfileNav.defaultProps = {
    pages: ["Personal Details", "Experience", "Education"]
};

ProfileNav.propTypes = {
    pages: PropTypes.array.isRequired
};

export default ProfileNav;

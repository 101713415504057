import styled from 'styled-components'

export const Container = styled.div`
    padding: 20px 40px;
    border-bottom: 1px solid rgba(150, 150, 150, 0.11);
    display: flex;
    justify-content: space-between;
`
export const IconContainer = styled.div`
    border-left: 3px solid ${ props => props.borderColor };
    margin: auto 0;
    padding-left: 20px;
`
export const Icon = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
`
export const Identity = styled.div`
    border-right: 1px solid rgba(206, 206, 206, 0.4);
    display: flex;
    justify-content: space-between;
    margin: auto 0;
`

export const IdentityInfo = styled.div`
    padding: 0 20px;

    .invite-card-identity-name {
        font-family: Roboto Slab;
        font-size: 18px;
        line-height: 132%;
        color: #323232;
    }

    .invite-card-identity-email {
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        color: rgba(15, 15, 15, 0.46);
    }
`;

export const DateContainer = styled.div`
    border-right: 1px solid rgba(206, 206, 206, 0.4);
    padding-right: 20px;
    display: flex;
    justify-content: center;
`
export const DateInner = styled.div`
    margin: auto 0;
    display: flex;
`
export const DateIcon = styled.div`
    margin-right: 20px;
`
export const Date = styled.div`
    margin: 0;
`
export const Status = styled.div`
    border-right: 1px solid rgba(206, 206, 206, 0.4);
`
export const StatusIcon = styled.div`
    margin: 0;
`
export const StatusInfo = styled.div`
    margin: 0;
`
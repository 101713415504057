import React from 'react'
import styled from 'styled-components'


const Container = styled.div`
    padding: 30px;
    background: ${ props => props.active ? "#ffffff" : null };
    box-shadow: -9px 19px 40px rgba(0, 0, 0, 0.02);
    border-radius: 5px 0 0 5px;
    cursor: pointer;
`;
const Header = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 3px;
    color: ${props =>
        props.active
            ? "rgba(67, 67, 67, 0.61)"
            : "rgba(255, 255, 255, 0.61)"};
`;
const Main = styled.div`
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: ${ props => props.active ? "#1c414f" : "#fff"};
`; 

const Tab = ({ header, main, onClick, active }) => {
    return (
        <Container className="registration-form-tab" onClick={onClick} active={active} >
            <Header active={active}>{header}</Header>
            <Main active={active}>{main}</Main>
        </Container>
    )
}

export default Tab

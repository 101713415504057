import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    padding: 40px;
`
const SVGs = styled.div`
    display: flex;
    justify-content: space-evenly;
    padding-top: 60px;
    flex-wrap: wrap;
`
const IMG = styled.img`
    margin: 0;
`
const Main = styled.div`
    margin: 0 auto;
    font-weight: 500;
    font-size: 22px;
    line-height: 152%;
    text-align: center;

    color: #abb2c4;
`;

const Clients = () => {
    return (
        <Container>
            <Main>More than 10,000 businesses have a use case for Dossr.io </Main>
            <SVGs>
                <IMG src={require('../../assets/svgs/glassdoor.svg')} alt="glassdoor" />
                <IMG src={require('../../assets/svgs/indeed.svg')} alt="indeed" />
                <IMG src={require('../../assets/svgs/linkedin.svg')} alt="linkedin" />
                <IMG src={require('../../assets/svgs/careerbuilder.svg')} alt="careerbuilder" />
                <IMG src={require('../../assets/svgs/zip.svg')} alt="zip.svg" />
            </SVGs>
        </Container>
    )
}

export default Clients

import React from "react";
import { Link } from 'react-router-dom'
import styled from "styled-components";
import UserNavInfo from "./UserNavInfo";
import Notifier from "../Notifier";

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(221, 221, 221, 0.37);

    .nav-btn-adjust {
        line-height: 30px;
    }
`;
const Left = styled.div`
    border-right: 1px solid rgba(221, 221, 221, 0.37);
    padding: 20px 20px 20px 40px;
    display: flex;
    
    .profile-nav-logo {
        text-align: center;
        display: flex;
        justify-content: center;

        .nav-logov2 { 
            margin: auto 0;
        }
    }
`;
const Right = styled.div`
    display: flex;
    .button-box {
        border-left: 1px solid rgba(221, 221, 221, 0.37);
        padding: 20px;
        text-align: center;
        display: flex;
    }
`;

const NavHeader = ({ name, career, notifications }) => {
    return (
        <Container>
            <Left>
                <div className="profile-nav-logo">
                    <Link to="/">
                        <img
                            src={require("../../assets/svgs/profileNav/logoV2.svg")}
                            alt="profile icon logo"
                            className="nav-logoV2"
                        />
                    </Link>
                </div>
            </Left>
            <Right>
                <div className="button-box nav-btn-adjust">
                    <Notifier notifications={notifications}>
                        <img
                            src={require("../../assets/svgs/profileNav/bell.svg")}
                            alt="nav icon"
                        />
                    </Notifier>
                </div>
                <div className="button-box nav-btn-adjust">
                    <Notifier notifications={notifications}>
                        <img
                            src={require("../../assets/svgs/profileNav/mail.svg")}
                            alt="nav icon"
                        />
                    </Notifier>
                </div>
                <div className="button-box">
                    <UserNavInfo name={name} career={career} />
                </div>
            </Right>
        </Container>
    );
};

NavHeader.defaultProps = {
    name: "Joel Henderson",
    career: "Human Resources",
    notifications: true
};

export default NavHeader;

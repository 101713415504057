import styled from 'styled-components'

export const LoadingContainer = styled.div`
    position: absolute;
    height: 600px;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 12px;
`;
export const Loading = styled.div`
    color: #222;
    font-size: 40px;
    width: 100%;
    text-align: center;
    padding-top: 25%;
`;
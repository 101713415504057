import types from '../types/authentication'
import authAPI from '../api/user'

const { USER_LOGIN, USER_SIGNUP } = types

export const login = user => ({
    type: USER_LOGIN,
    user
});

export const signup = userData => ({
    type: USER_SIGNUP
})


export const userLogin = user => dispatch => 
    authAPI.userLogin(user).then( res => {
        dispatch(login(user))
        return res
    })
import React from 'react'
import styled from 'styled-components'
import { YH, H2, SubTxt } from './Typography';
import ArrowBtn from './Buttons/ArrowBtn';


const Container = styled.div`
    width: 500px;

    .header {
        margin-bottom: 15px;
    }

    .btn-container {
        padding-top: 40px;
    }
    
`;


const InfoPanel = ({ section, title, btnText, subText  }) => {
    return (
        <Container className="employment right">
            <YH>{section}</YH>
            <H2 className="header">{title}</H2>
            <SubTxt>{subText}</SubTxt>
            <div className="btn-container">
                <ArrowBtn>{btnText}</ArrowBtn>
            </div>
        </Container>
    );
}

export default InfoPanel

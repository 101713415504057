import React from 'react'
import NavHeader from '../../components/Profile/NavHeader';
import ProfileNavigation from '../../components/Profile/ProfileNavigation';
import ProfileHead from '../../components/Profile/Heads/ProfileHead';
import Profile from '../../components/Profile/UserProfile';
import Footer from '../../components/Footer';


const ProfilePage = ({ current }) => {
    return (
        <div>
            <NavHeader />
            <ProfileNavigation current={current} />
            <ProfileHead />
            <div style={{ paddingTop: 120, backgroundColor: "#F4F7FD" }}>
                <Profile />
            </div>
            <Footer />
        </div>
    );
}

export default ProfilePage

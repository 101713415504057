import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";
import { Helmet } from "react-helmet";
import Home from "./pages/Home";
import ProfilePage from "./pages/Profile";
import ResetPassPage from "./pages/ResetPassPage";
import SendResetLinkPage from "./pages/SendResetLink";
import OrganizationPage from "./pages/Organization";

function App() {
    return (
        <Router>
            <Helmet>
                <title>Dossr.io</title>
                <link rel="shortcut icon" href={require("./favicon.ico")} />
            </Helmet>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/profile" exact component={ProfilePage} />
                <Route path="/organization" exact component={OrganizationPage} />
                <Route path="/send_reset_link" exact component={SendResetLinkPage} />
                <Route path="/pass_reset/:timestamp&:signature" exact component={ResetPassPage} />
            </Switch>
        </Router>
    );
}

export default App;

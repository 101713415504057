import styled from "styled-components";

export const Nav = styled.div`
    background: linear-gradient(180deg, #0bb1e3 -33.33%, #0b7ca3 100%);
    border-radius: 12px 0 0 12px;
    width: 400px;
    height: 600px;
`;
export const Rendered = styled.div`
    width: 100%;
    height: 600px;

    background: #ffffff;
    box-shadow: -9px 19px 40px rgba(0, 0, 0, 0.02);
    border-radius: 0 12px 12px 0;
`;

/** Business Components */
export const Container = styled.div`
    padding: 0;
    height: 600px;
    overflow-y: hidden;
    ::-webkit-scrollbar {
        display: none;
    }
`;
export const Scrollable = styled.div`
    overflow-y: scroll;
    height: 500px;
    ::-webkit-scrollbar {
        display: none;
    }
`;
export const InputContainer = styled.form`
    padding: 40px 50px 60px;
`;
export const Spacer = styled.div`
    padding: 10px;
    width: 100%;
`;
export const Pair = styled.div`
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 800px) {
        flex-direction: column;
    }
`;
export const PairSpace = styled.div`
    padding: 10px;
    width: 100%;
`;
export const BtnContainer = styled.div`
    padding: 40px 0 0 10px;
`;
export const Standards = styled.div`
    padding: 20px 20px 50px;
    border-top: 1px solid #ebebeb;
    display: flex;
    justify-content: space-between;

    .registration-standards {
        width: 100px;
        height: auto;
    }
`;

export const Error = styled.div`
    color: #f55;
    padding-left: 10px;
    font-size: 12px;
`
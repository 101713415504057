import React from 'react'
import styled from 'styled-components'
import Card from '../../Card';


const Title = styled.div`
    background: #f9fafe;
    border-radius: 10px 10px 0 0;
    padding: 20px 40px;
`;
const Body =styled.div`
    padding: 20px 40px;
`
const Test = styled.div`
    display: flex;
    justify-content: space-between;
`
const InfoCard = ({ title }) => {
    return (
        <Card>
            <Title>{title}</Title>
            <Body>
                <Test>
                    <div>left</div>
                    <div>right</div>
                </Test>
            </Body>
        </Card>
    )
}

export default InfoCard

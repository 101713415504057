import React from 'react'
import styled from 'styled-components'


const Container = styled.div`
    background: #fbfdff;
    box-shadow: 0px 10px 40px rgba(91, 91, 91, 0.10);
    border-radius: 10px;
    width: 100%;
`;

const Card = ({ children}) => {
    return (
        <Container>
            {children}
        </Container>
    )
}

export default Card

import React from 'react'
import PropTypes from 'prop-types';
import * as UI from './styles'
import statusColor from '../../../../functions/statusColor';
import ReminderBtn from '../../../Buttons/ReminderBtn';



const InviteCard = ({ icon, status, name, email, date }) => {

    return (
        <UI.Container>
            <UI.Identity>
                <UI.IconContainer borderColor={statusColor(status)}>
                    <UI.Icon src={icon} alt="icon" />
                </UI.IconContainer>
                <UI.IdentityInfo>
                    <div className="invite-card-identity-name">{name}</div>
                    <div className="invite-card-identity-email">{email}</div>
                </UI.IdentityInfo>
            </UI.Identity>
            <UI.DateContainer>
                <UI.DateInner>
                    <UI.DateIcon>
                        <img
                            src={require("../../../../assets/svgs/date-icon.svg")}
                            alt="date icon"
                        />
                    </UI.DateIcon>
                    <UI.Date>
                        <div className="invite-card-date">{date}</div>
                        <div className="invite-card-issue-date-text">
                            Issue Date
                        </div>
                    </UI.Date>
                </UI.DateInner>
            </UI.DateContainer>
            <UI.Status>
                <UI.StatusIcon>
                    <img
                        src={require("../../../../assets/svgs/clerk.svg")}
                        alt="clerk icon"
                    />
                </UI.StatusIcon>
                <UI.StatusInfo>
                    <div className="invite-card-invite-status">
                        Candidate Signed Up
                    </div>
                    <div className="invite-card-user-status">
                        Awaiting Profile
                    </div>
                </UI.StatusInfo>
            </UI.Status>
            <div>
                <ReminderBtn text="Set Reminder" />
            </div>
        </UI.Container>
    );
}
InviteCard.defaultProps = {
    icon: require("../../../../assets/images/profilepic.png"),
    name: "James Flincher",
    email: "jamesflinch@gmail.com",
    date: "09/08/019"
};
InviteCard.propTypes = {
    status: PropTypes.string
}

export default InviteCard

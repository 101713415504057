import React from "react";
import ReactDOM from "react-dom";
import axios from 'axios'
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import store from "./redux/store";
import * as serviceWorker from "./serviceWorker";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
//axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";




ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

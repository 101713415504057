import styled from 'styled-components'
import woman from "../../../assets/images/education_lady.png";

export const Container = styled.div`
    display: flex;  
    margin-top: 400px;
    
    .left {
        position: relative;
        width: 600px;
    }
    .right {
        padding: 50px 0 50px 20px;
    }
`;

export const BGIMG = styled.div`
    background: url(${woman}) no-repeat;
    background-size: 830px auto;
    width: 600px;
    height: 1000px;
    position: absolute;
    top: -400px;
`
import React from "react";

function Requests({color}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 19">
            <g clipPath="url(#clip0)">
                <path
                    fill={color}
                    fillOpacity="0.51"
                    d="M18.75 6.538a.96.96 0 00-.71-.313H.96a.96.96 0 00-.955 1.047l.911 9.79c.046.494.46.87.955.87h15.26a.959.959 0 00.954-.87l.912-9.79a.958.958 0 00-.246-.734zm-6.052 9.386H6.304a.959.959 0 01-.96-.958 2.364 2.364 0 012.362-2.362h.688a2.296 2.296 0 01-1.188-2.009A2.296 2.296 0 019.5 8.301a2.296 2.296 0 012.294 2.294c0 .864-.48 1.618-1.189 2.01h.689a2.364 2.364 0 012.363 2.36c0 .53-.43.96-.959.96zM17.155 4.44a.72.72 0 01-.72.72H2.565a.72.72 0 110-1.439h13.872a.72.72 0 01.719.72zm-1.899-2.653a.72.72 0 01-.719.72H4.462a.72.72 0 110-1.439h10.075a.72.72 0 01.72.72z"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <path fill="#fff" d="M0 0H19V19H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

Requests.defaultProps = {
    color: "#717171"
};

export default Requests;

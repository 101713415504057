import React from 'react'
import styled from 'styled-components'
import * as Typo from '../../Typography'
import values from "./value-data"
import Value from './Value'


const Container = styled.div`
    padding: 120px 20px 20px;
    .title {
        display: flex;
        justify-content: center;
    }
    width: 100%;
`;
const Values = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 40px;
`

const HowItWorks = () => {
    return (
        <Container>
            <div className="title">
                <Typo.H2 fz="42px" >How It Works</Typo.H2>
            </div>
            <Values>
                { values.map((val, i) => <Value 
                    title={val.title} 
                    content={val.content} 
                    src={val.src} alt={val.alt} 
                    key={i}
                />)}
            </Values>
        </Container>
    )
}

export default HowItWorks

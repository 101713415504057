import React from 'react'
import styled from 'styled-components'
import ProfileNavBtn from './ProfileNavBtn';

import Dashboard from '../SVGs/Dashboard'
import Profile from '../SVGs/Profile'
import Settings from '../SVGs/Settings';
import Invite from '../SVGs/Invite'
import Requests from '../SVGs/Requests'
import Organizations from '../SVGs/Organizations'


const Container = styled.div`
    display: flex;
    justify-content: center;
`

const ProfileNavigation = ({ current }) => {
    return (
        <Container>
            <ProfileNavBtn location="Dashboard" current={current} >
                <Dashboard />
            </ProfileNavBtn>
            <ProfileNavBtn location="Profile" current={current} >
                <Profile />
            </ProfileNavBtn>
            <ProfileNavBtn location="Invite" current={current} >
                <Invite />
            </ProfileNavBtn>
            <ProfileNavBtn location="Requests" current={current} >
                <Requests />
            </ProfileNavBtn>
            <ProfileNavBtn location="Organizations" current={current} >
                <Organizations />
            </ProfileNavBtn>
            <ProfileNavBtn location="Settings" current={current} >
                <Settings />
            </ProfileNavBtn>
        </Container>
    )
}

export default ProfileNavigation

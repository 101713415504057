import React from "react";
import PropTypes from 'prop-types'
import styled from "styled-components";


/**
 * @param {string} icon The Url of the icon to be shown on profile. Is required
 */

const Container = styled.div`
    display: flex;
    padding: 20px 0;

    .base-user-info {
        padding-left: 20px;
        padding-top: 8px;
    }
`;
const Name = styled.div`
    font-family: Roboto Slab;
    font-size: 14px;
    line-height: 20px;
    color: #1d2047;
    margin-bottom: 10px;
`;
const Info = styled.div`
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 146%;
    color: #838383;
`;


const BaseUser = ({ icon, primary, name, career, email }) => {
    const info = career ? career : email;
    return (
        <Container>
            <img src={icon} alt="profile pic" />
            <div className="base-user-info">
                <Name>{name}</Name>
                <Info>{info}</Info>
            </div>
        </Container>
    );
};

BaseUser.propTypes = {
    icon: PropTypes.string.isRequired
}
BaseUser.defaultProps = {
    icon: require('../../assets/images/home reviews/profilepic.png')
}

export default BaseUser;

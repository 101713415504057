import React from "react";
import Splash from "../../components/Home/Splash";
import Clients from "../../components/Home/Clients";
import HowItWorks from "../../components/Home/HowItWorks";
import CredSect from "../../components/Home/CredSect";
import Slider from "../../components/Slider";
import Footer from "../../components/Footer";
import Employment from "../../components/Home/Employment";
import Education from "../../components/Home/Education";

import services from "../../components/Cards/services-data";
import reviews from "../../components/Cards/ratings-data";
import "./styles.css";
import Service from "../../components/Cards/Service";
import CustomerRating from "../../components/Cards/CustomerRating";
import Modal from "../../components/Modal";
import LoginForm from "../../components/Forms/Auth/Login";
import RegistrationForm from "../../components/Forms/Auth/Registration";

class Home extends React.Component{
    state = {
        showModal: false,
        formToRender: ''
    }

    handleLoginClick = e => {
        this.setState({ 
            showModal: true,
            formToRender: "login"
        })
        console.log("login has been clicked");
    }
    
    handleSignUpClick = e => {
        this.setState({ 
            showModal: true,
            formToRender: "signup"
         });
        console.log("signup has been clicked");
    }

    handleModalClose = e => {
        this.setState({ showModal: false });
        console.log("modal has been closed.")
    }

    render(){
        const { showModal, formToRender } = this.state
        const Form =
            formToRender === "signup"
                ? () => <RegistrationForm />
                : () => <LoginForm />;
        return (
            <div className="home-container" >
                <Modal show={showModal} onClick={this.handleModalClose} >
                    <Form />
                </Modal>
                <div>
                    <Splash
                        login={this.handleLoginClick}
                        signup={this.handleSignUpClick}
                    />
                    <div className="gray-section">
                        <Clients />
                        <HowItWorks />
                        <CredSect />
                    </div>
                    <div className="landing-feautures-section">
                        <Slider
                            title="Powerful set of Services and Features"
                            mb="100px"
                        >
                            {services.map((service, i) => {
                                return (
                                    <Service
                                        icon={service.icon}
                                        service={service.service}
                                        bg={service.bg}
                                        key={i}
                                    />
                                );
                            })}
                        </Slider>
                        <Employment />
                        <Slider title="What our clients are saying about the product">
                            {reviews.map((review, i) => {
                                return (
                                    <CustomerRating
                                        stars={review.stars}
                                        comment={review.comment}
                                        user={review.user}
                                        key={i}
                                    />
                                );
                            })}
                        </Slider>
                    </div>
                    <div className="gray-section">
                        <Education />
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default Home;

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Input from '../../Input';
import Button from '../../../Buttons/Button';
import api from '../../../../redux/api/user'
import { Container, ButtonContainer, Title } from './styles'


class ResetPassword extends Component {
    state = {
        form: {
            user_id: "",
            timestamp: "",
            signature: "",
            password: ""
        },
        status: {
            loading: false,
            error: null
        }
    };

    componentDidMount() {        
        console.log(this.props.match);
        const { timestamp, signature } = this.props.match.params
        this.setState({
            form: {
                timestamp: timestamp,
                signature: signature
            }
        })
    }

    handleChange = e => {
        this.setState({
            form: { 
                ...this.state.form,
                [e.target.name]: e.target.value 
            }
        });
    };

    handleSubmit = e => {
        e.preventDefault()
        console.log(this.state.form)
        api.resetPass(this.state.form).then(res => {
            console.log(res)
        }).catch( err => console.log(err))
    }

    render() {
        return (
            <Container>
                <Title>Reset Password</Title>
                <form onSubmit={this.handleSubmit}>
                    <div className="reset-pass-input-spacer">
                        <Input
                            title="User ID"
                            name="user_id"
                            primary={false}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="reset-pass-input-spacer">
                        <Input
                            title="New password"
                            name="password"
                            primary={false}
                            onChange={this.handleChange}
                        />
                    </div>
                    <ButtonContainer>
                        <Button>submit</Button>
                    </ButtonContainer>
                </form>
            </Container>
        );
    }
}

export default withRouter(ResetPassword);
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 600px;
    border-radius: 12px;
`;
export const Left = styled.div`
    margin: 0;
    padding: 40px;
    background-color: #fff;
    box-shadow: -9px 19px 40px rgba(0, 0, 0, 0.02);
    border-radius: 12px 0px 0px 12px;
    width: 100%;

    .form-header-container {
        padding-top: 10px;
        padding-right: 20px;
    }

    .form-image-section {
        background: url(${require("../../../../assets/svgs/auth/dots.svg")})
            right no-repeat;
        background-size: 250px auto;
        padding-top: 60px;
        margin-top: 20px;
        margin-bottom: 40px;
    }
    img {
        width: 100%;
    }
    .no-account-link {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;
        padding: 0 20px;

        color: #a2a2a2;
    }
    .create-account-link {
        font-family: Roboto Slab;
        font-size: 24px;
        line-height: 19px;
        color: #0e569a;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
    }
`;
export const Right = styled.form`
    background: linear-gradient(200.45deg, #0bb1e3 -34.33%, #0b7ca3 93.2%);
    border-radius: 0px 12px 12px 0px;
    width: 100%;
    padding-top: 30px;

    .login-auth-error {
        color: #f22;
        padding-bottom: 20px;
        font-size: 12px;
    }
    .login-form-section {
        padding: 40px 60px 40px;

        .login-form-input {
            margin-bottom: 30px;
        }
    }

    .forget-pass-link {
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        text-decoration-line: underline;
        color: #ffffff;
        padding-top: 20px;
        cursor: pointer;
    }
`;

export const BtnSection = styled.div`
    margin-top: 20px;

    .login-form-btn {
        padding: 20px;
        border-radius: 5px;
        font-weight: 500;
        font-size: 16px;
        line-height: 125.5%;
        border: none;
        width: 65%;
        cursor: pointer;
    }
    .login-form-btn-primary {
        outline: none;
        box-shadow: 0px 20px 40px rgba(17, 124, 164, 0.19);
        letter-spacing: 0.155em;
        display: flex;
        justify-content: space-between;
        background: #ffffff;
        color: #0c97c3;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    .login-form-btn-linkedin {
        color: #fff;
        border: 1px solid #fff;
        display: flex;
        justify-content: space-between;
        padding-right: 10px;

        .linkedin-container {
            background: #fff;
            border-radius: 3px;
            padding: 10px 10px 5px;
            margin: -10px 0;
        }
    }
`;



import Validator from "validator";

export default {
    email: email => Validator.isEmail(email),
    password: pass => {
        return (
            Validator.isLength(pass, { min: 6, max: undefined }) &&
            Validator.matches(pass, /([A-Za-z0-9@_.+\- ])/)
        );
    },
    username: name => Validator.isLength(name, { min: 3, max: 150 })
};

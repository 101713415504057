export default [
    {
        stars: 5,
        comment:
            "Dossr is transparent, mobile-optimized workflow, eConsent, and Comments for Context deliver a 95% satisfaction rating",
        user: {
            icon: require("../../assets/images/home reviews/profilepic.png"),
            name: "Danny Doland",
            career: "Dentist"
        }
    },
    {
        stars: 5,
        comment:
            "Dossr is transparent, mobile-optimized workflow, eConsent, and Comments for Context deliver a 95% satisfaction rating",
        user: {
            icon: require("../../assets/images/home reviews/profilepic.png"),
            name: "Danny Doland",
            career: "Dentist"
        }
    },
    {
        stars: 5,
        comment:
            "Dossr is transparent, mobile-optimized workflow, eConsent, and Comments for Context deliver a 95% satisfaction rating",
        user: {
            icon: require("../../assets/images/home reviews/profilepic.png"),
            name: "Danny Doland",
            career: "Dentist"
        }
    }
];
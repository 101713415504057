import React from 'react'
import * as UI from './styles'
import Navigation from '../../Navigation';
import ArrowBtn from '../../Buttons/ArrowBtn';


const Splash = ({ login, signup }) => {
    return (
        <UI.Container>
            <Navigation login={login} signup={signup} />
            <UI.Gradient />
            <UI.Hero>Intelligent Solutions for Credential Verification</UI.Hero>
            <UI.SubHero>
                Helping HR leaders, entrepreneurs in businesses from all
                industries build great teams.
            </UI.SubHero>
            <UI.BtnContainer>
                <ArrowBtn>Get Started</ArrowBtn>
            </UI.BtnContainer>
        </UI.Container>
    );
}

export default Splash

import React, { Component } from 'react'
import * as UI from "../profile-styles";
import ProfileNav from '../ProfileNav';
import SearchRez from './SearchRez';


export default class OrgProfile extends Component {
    render() {
        return (
            <UI.Container>
                <ProfileNav pages={["pending", "completed", "invited candidates"]}/>
                <SearchRez />
            </UI.Container>
        )
    }
}

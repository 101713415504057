import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { userLogin } from "../../../../redux/actions/auth";
import * as Form from "./style";
import { LoadingContainer, Loading } from '../../Loading'
import Input from "../../Input";
import FormHeader from "../FormHeader";
import { FormHeaderTitle } from "../../../Typography";

class LoginForm extends React.Component {
    state = {
        credentials: {
            login: "",
            password: ""
        },
        status: {
            loading: false,
            error: null
        }
    };

    handleChange = e => {
        this.setState({
            ...this.state,
            credentials: {
                ...this.state.credentials,
                [e.target.name]: e.target.value
            }
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        const user = this.state.credentials;

        this.setState({
            status: {
                loading: true
            }
        });
        this.props
            .userLogin(user)
            .then(res => {
                this.props.history.push("/profile");
                this.setState({
                    status: {
                        loading: false
                    }
                });
            })
            .catch(err => {
                console.log(err.response)
                this.setState({
                    status: {
                        loading: false,
                        error: err.response.data.detail
                    }
                });
            });
    };

    render() {
        const { loading, error } = this.state.status;

        return (
            <div>
                {loading && (
                    <LoadingContainer>
                        <Loading> Loading...</Loading>
                    </LoadingContainer>
                )}
                <Form.Container>
                    <Form.Left>
                        <div className="form-header-container">
                            <FormHeaderTitle color="#127899">
                                Welcome Back
                            </FormHeaderTitle>
                        </div>
                        <div className="form-image-section">
                            <img
                                src={require("../../../../assets/svgs/auth/manonchair.svg")}
                                alt="manonchair"
                            />
                        </div>
                        <div className="no-account-link">
                            Don’t have an account?
                        </div>
                        <div className="create-account-link">
                            <div>create an account</div>
                            <div>→</div>
                        </div>
                    </Form.Left>
                    <Form.Right autocomplete="off" onSubmit={this.handleSubmit}>
                        <FormHeader
                            header="Login"
                            description="Create a new profile to get started "
                            primary={true}
                        />
                        <div className="login-form-section">
                            {!!error && (
                                <div className="login-auth-error">{error}</div>
                            )}
                            <div className="login-form-input">
                                <Input
                                    name="login"
                                    title="Email Address"
                                    onChange={this.handleChange}
                                    type="email"
                                    autocomplete="off"
                                    primary={true}
                                    required
                                />
                            </div>
                            <Input
                                name="password"
                                title="Password"
                                onChange={this.handleChange}
                                type="password"
                                autocomplete="off"
                                primary={true}
                                required
                            />
                            <div className="forget-pass-link">
                                Forgot password?
                            </div>
                            <Form.BtnSection>
                                <button className="login-form-btn login-form-btn-primary">
                                    <div>Login</div>
                                    <div>→</div>
                                </button>
                                <div className="login-form-btn login-form-btn-linkedin">
                                    <div>Login With</div>
                                    <div className="linkedin-container">
                                        <img
                                            src={require("../../../../assets/svgs/auth/LinkedIn.svg")}
                                            alt="linkedin icon"
                                        />
                                    </div>
                                </div>
                            </Form.BtnSection>
                        </div>
                    </Form.Right>
                </Form.Container>
            </div>
        );
    }
}

export default connect(
    null,
    { userLogin }
)(withRouter(LoginForm));

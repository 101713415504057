import React from "react";
import NavHeader from "../../components/Profile/NavHeader";
import ProfileNavigation from "../../components/Profile/ProfileNavigation";
import Footer from "../../components/Footer";
import OrgProfile from "../../components/Profile/OrgProfile";
import OrgProfileHead from "../../components/Profile/Heads/OrgProfileHead";

const OrgProfilePage = ({ current }) => {
    return (
        <div>
            <NavHeader />
            <ProfileNavigation current={current} />
            <OrgProfileHead/>
            <div style={{ paddingTop: 120, backgroundColor: "#F4F7FD" }}>
                <OrgProfile />
            </div>
            <Footer />
        </div>
    );
};

export default OrgProfilePage;

import React from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    justify-content: center;
`;

const Number = styled.div`
    padding: 10px;
    cursor: pointer;
    border-bottom: ${props => (props.current ? `1px solid #fff` : null)};
`;

const PaginationNav = ({ pages = [1, 2, 3, 4], pageClick, currentPage }) => {
    return (
        <Container>
            {pages.map((page, i) => (
                <Number
                    key={i}
                    onClick={e => pageClick(e, i)}
                    current={currentPage === i}
                >
                    {i + 1}
                </Number>
            ))}
        </Container>
    );
};

PaginationNav.defaultProps = {
    totalPages: [1, 2, 3, 4]
};

export default PaginationNav;

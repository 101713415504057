import React from 'react'
import styled from 'styled-components'
import { FormHeaderTitle } from '../../Typography';


const Container = styled.div`
    padding: 20px 0 20px 60px;
    border-bottom: 1px solid
        ${props => (props.primary ? "rgba(255, 255, 255, 0.65)" : "#EBEBEB")};
    .business-form-header {
        color: ${props => (props.primary ? "#fff" : "#656565")};
    }
`;

const Description = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${ props => props.primary ? "rgba(255, 255, 255, 0.65)" : "#A8A8A8"};
`;


const FormHeader = ({ header, description, primary }) => {
    return (
        <Container primary={primary}>
            <FormHeaderTitle>
                <div className="business-form-header">{header}</div>
            </FormHeaderTitle>
            <Description primary={primary}>{description}</Description>
        </Container>
    );
}

FormHeader.defaultProps = {
    primary: true
}

export default FormHeader

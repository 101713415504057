import axios from "axios";


export default {
    userLogin: user =>
        axios.post(`/accounts/login/`, { ...user }).then(res => {
            console.log(res);
            return res;
        }),
    signUp: details =>
        axios.post(`/accounts/register/`, { ...details }).then(res => {
            console.log(res);
            return res;
        }),
    resetPass: resetData =>
        axios.post(`/accounts/reset-password/`, { ...resetData }).then(res => {
            console.log("from resetPass API")
            console.log(res);
            return res;
        })
};

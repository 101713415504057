import React from 'react'
import styled from 'styled-components'
import { H2 } from '../Typography';
import ArrowBtn from '../Buttons/ArrowBtn';


const Container = styled.div`
    padding: 60px 0;

    .title {
        padding-left: 160px;
        max-width: 800px;
        padding-bottom: 30px;
    }
    .btn-container {
        padding-left: 160px;
    }
`;
const Main = styled.div`
    padding-left: 80px;
`
const IMG = styled.img`
    width: 100%;
`

const CredSect = () => {
    return (
        <Container>
            <div className="title" >
                <H2 fz="42px">Request a Credential Verification in a minute</H2>
            </div>
            <div className="btn-container">
                <ArrowBtn> GET </ArrowBtn>
            </div>
            <Main>
                <IMG src={ require("../../assets/images/verification.png")} alt="app example image" />
            </Main>
        </Container>
    );
}

export default CredSect

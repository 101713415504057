import React from 'react'
import styled from 'styled-components'
import Card from '../../Card';



const OuterCard = styled.div`
    margin: 10px;
    min-width: 250px;
`
const InnerCard = styled.div`
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;

    .orgcard-icon-container {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;

        .orgcard-icon-bg {
            padding: 15px;
            border-radius: 50%;
            line-height: 8px;
            background-color: ${props => props.iconBG};
            margin: 0 auto;
        }
        img {
            margin: 0 auto;
        }
    }
    .orgcard-value {
        color: #0e569a;
        font-family: Roboto Slab;
        font-weight: 800;
        font-size: 40px;
        margin-bottom: 10px;
    }

    .orgcard-title {
        color: #6f6f6f;
        opacity: 0.8;
        font-weight: 500;
    }
`;

const OrgCard = ({ icon, value, title, iconBG }) => {
    return (
        <OuterCard>
            <Card>
                <InnerCard iconBG={iconBG}>
                    <div className="orgcard-icon-container">
                        <div className="orgcard-icon-bg" >
                            <img src={icon} alt="icon" />
                        </div>
                    </div>
                    <div className="orgcard-value">{value}</div>
                    <div className="orgcard-title">{title}</div>
                </InnerCard>
            </Card>
        </OuterCard>
    );
}

OrgCard.defaultProps = {
    icon: require("../../../assets/svgs/OrgCard/tick.svg")
}

export default OrgCard

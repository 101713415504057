import React from 'react'
import styled from 'styled-components'
import { H3 } from '../../Typography';
import Button from '../../Buttons/Button';


const Container = styled.div`
    padding-top: 60px;
    width: 300px;

    .centerer {
        display: flex;
        justify-content: center;
    }
    .btn {
        padding-top: 20px;
    }
`
const IMG = styled.img`
    margin: 0;
    width: 100%;
`
const Text = styled.div`
    padding: 10px;

    .content {
        width: 240px;
        text-align: center;
        margin: 0 auto;
        padding-top: 20px;
        color: #8c8c8c;
    }
`;
const Value = ({title, content, src, alt}) => {
    return (
        <Container>
            <IMG src={src} alt={alt} />
            <Text>
                <div className="centerer">
                    <H3>{title}</H3>
                </div>
                <div className="content">{content}</div>
            </Text>
            <div className="centerer btn">
                <Button>Learn More</Button>
            </div>
        </Container>
    );
}

export default Value

export default ( status ) => {
    let color;
    switch (status) {
        case "accepted":
            color = "#21AF27";
            break;
        case "pending":
            color = "#FAA949";
            break;
        default:
            color = "#FA4969";
            break;
    }
    return color
}
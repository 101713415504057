import React from "react";

function Dashboard({ color }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 19">
            <path
                fill={color}
                fillOpacity="0.51"
                d="M14.902 4.987L13.15 8.658a2.664 2.664 0 01-.38 2.422 2.58 2.58 0 01-2.071 1.06c-.561 0-1.095-.181-1.546-.525a2.633 2.633 0 01-1.01-1.737A2.665 2.665 0 018.63 7.92a2.58 2.58 0 012.072-1.06c.12 0 .238.01.356.027l2.847-2.662a.624.624 0 01.807-.04.656.656 0 01.19.802zM10.7 0C8.73 0 6.848.622 5.256 1.798a1.094 1.094 0 00-.237 1.506 1.042 1.042 0 001.475.242A7.033 7.033 0 0110.7 2.158c3.964 0 7.188 3.293 7.188 7.342 0 4.048-3.225 7.342-7.188 7.342-3.875 0-7.042-3.15-7.18-7.074l.788.137a.83.83 0 00.142.012h.01a.854.854 0 00.837-.863.875.875 0 00-.074-.352l-1.252-3.45a.836.836 0 00-1.443-.25L.194 7.876a.878.878 0 00-.141.85c.105.29.353.5.65.551l.703.122a1.11 1.11 0 00-.005.1c0 5.238 4.171 9.5 9.3 9.5C15.827 19 20 14.738 20 9.5S15.828 0 10.7 0z"
            />
        </svg>
    );
}

Dashboard.defaultProps = {
    color: "#717171"
}

export default Dashboard;

import React from "react";
import styled from "styled-components";
import InfoPanel from "../InfoPanel";

const Container = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 0 40px;
`;
const IMG = styled.img`
    width: 100%;
`;

const Employment = () => {
    return (
        <Container>
            <div className="employment left">
                <IMG
                    src={require("../../assets/images/employment.png")}
                    alt="section media"
                />
            </div>
            <div className="employment right">
                <InfoPanel
                    section="Employment"
                    title="Credential Verification with a difference."
                    subText="Dossr is designed to provide tailor made solutions to service providers 
                            by automating the existing manual process during the credential screening 
                            processes prior to making decisions. Our focus is to develop innovative and 
                            agile solutions designed to meet the screening needs of our customers while 
                            anticipating the future evolution of the screening industry."
                    btnText="Integrations"
                />
            </div>
        </Container>
    );
};

export default Employment;

import styled from 'styled-components'
import bgmage from "../../../assets/images/splash-mage.png";


export const Container = styled.div`
    height: 600px;
    background: url(${bgmage}) no-repeat;
    max-width: 1440px;
    margin: 0 auto;
`;
export const Gradient = styled.div`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.62), rgba(0, 0, 0, 0.62)),
        linear-gradient(180deg, #0bb1e3 0%, #0d6fae 48.44%, #0f2976 100%);
    mix-blend-mode: multiply;
    opacity: 0.7;
    position: absolute;
    height: 600px;
    width: 100%;
    max-width: 1440px;
    top: 0;
`;

export const Hero = styled.div`
    position: relative;
    margin: 0 auto;
    padding-top: 140px;
    max-width: 600px;
    font-family: Roboto Slab;
    font-size: 42px;
    line-height: 52px;
    text-align: center;
    color: #ffffff;
`;

export const SubHero = styled.div`
    position: relative;
    margin: 0 auto;
    padding-top: 20px;
    max-width: 442px;
    height: 48px;

    font-family: Roboto;
    font-size: 16px;
    line-height: 132%;
    text-align: center;
    letter-spacing: 0.04em;

    color: rgba(255, 255, 255, 0.87);
`;

export const BtnContainer = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 40px;
`;

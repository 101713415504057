import styled from 'styled-components'

export const Container = styled.div`
    background: linear-gradient(180deg, #0bb1e3 23.89%, #0f2976 142.12%);
    height: ${ props => props.ht };
    position: relative;
`;
Container.defaultProps = {
    ht: "200px"
}
export const CardContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 40px;
    width: 100%;
    top: 50px;
`;
export const ProfileHeadContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 40px;
`;
export const ProfileLeft = styled.div`
    display: flex;
`;
export const ProfilePic = styled.div`
    height: 135px;
    width: 135px;
    margin-right: 40px;
    display: flex;
    justify-content: center;

    img {
        border-radius: 50%;
        width: 100%;
        height: auto;
    }
`;
export const InfoSection = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    div {
        display: flex;
    }
    .profile-head-name {
        font-family: Roboto Slab;
        font-size: 24px;
        line-height: 132%;
        color: #323232;
        margin: 0 0 10px 0;
    }
`;
export const ProfileBtn = styled.div`
    border: 1px solid rgba(13, 117, 179, 0.22);
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    padding: 5px 10px;
    margin: 10px 20px 5px 0;
    cursor: pointer;

    img {
        margin-right: 5px;
    }
`;
export const Aspect = styled.div`
    background: #f4f5f9;
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    line-height: 132%;
    color: #989eb7;
    text-align: center;
    padding: 5px 10px;
    margin: 10px 5px 5px 0;
`;
export const ProfileRight = styled.div`
    margin: 0;
`;



//Organization specific cards
export const OrgCardContainer = styled(CardContainer)`
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 40px 80px;
    top: 160px;
`

export const SearchArea = styled.div`
    padding: 60px 80px 0;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`
export const SearchAreaLeft = styled.div`    
    font-family: Roboto Slab;
    font-size: 36px;
    line-height: 47px;
    color: #FFFFFF;
`
export const SearchAreaRight = styled.div`    
    margin: 0;
`
import React from 'react'

const Metrics = ({ completion }) => {
    return (
        <div>
            {completion}
        </div>
    )
}

export default Metrics

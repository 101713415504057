import React from "react";

function Profile({color}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 17">
            <path
                fill={color}
                d="M16.219 12.569v3.786a.645.645 0 01-.645.645H1.426a.645.645 0 01-.645-.645v-3.786a3.13 3.13 0 012.305-3.015l3.09-.84a.645.645 0 01.728.3L8.5 11.78l1.596-2.765a.645.645 0 01.728-.3l3.09.84a3.13 3.13 0 012.305 3.015zM8.5 0C6.684 0 5.207 1.761 5.207 3.926S6.684 7.852 8.5 7.852s3.293-1.76 3.293-3.926C11.793 1.761 10.316 0 8.5 0z"
            />
        </svg>
    );
}

Profile.defaultProps = {
    color: "#717171"
};

export default Profile;

import styled from 'styled-components'

export const Container = styled.div`
    margin: 0;
`
export const Links = styled.div`
    background: linear-gradient(180deg, #0BB1E3 -33.33%, #0F2976 100%);

    .footer-links {
        height: 340px;
        display: flex;
    }
    .footer-bottom {
        border-top: 1px solid rgba(255, 255, 255, 0.22);
        padding: 30px 100px;
        display: flex;
        justify-content: space-between;
        color: #fff;
    }
`
export const FakeLink = styled.div`
    cursor: pointer;
`

import React, { Component } from 'react'
import ResetPassword from "../components/Forms/Auth/PasswordReset";

export default class ResetPassPage extends Component {
    render() {
        return (
            <div>
                <ResetPassword />
            </div>
        )
    }
}

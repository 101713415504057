import React from 'react'
import styled from 'styled-components'

const Container = styled.button`
    padding: ${ props => props.pad };
    font-size: ${props => props.fz };
    border: ${props =>
        props.secondary ? " 1px solid #fff" : " 2px solid rgba(12, 164, 217, 0.32)"};
    border-radius: ${props => (props.secondary ? "5px" : "4px")};
    color: ${props => (props.secondary ? "#fff" : "#0FA3D8;")};
    cursor: pointer;
    outline: none;
    background-color: transparent;
`;
Container.defaultProps = {
    fz: "14px",
    pad: "10px 20px"
};

const Button = ({ fz, alt, pad, onClick, children}) => {
    return (
        <Container secondary={alt} fz={fz} pad={pad} onClick={onClick} >
            {children}
        </Container>
    )
}

export default Button

import React from 'react'
import styled from 'styled-components'


const Container = styled.div`
    margin-right: 40px;
`
const Icon = styled.div`
    width: 30px;
`
const Data = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #989eb7;
`;

const IconUserInfo = ({icon, data}) => {
    return (
        <Container>
            <Icon>
                <img src={icon} alt="icon" />
            </Icon>
            <Data>{data}</Data>
        </Container>
    )
}

export default IconUserInfo

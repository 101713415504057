import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import orgAPI from "../../../../redux/api/organization";
import validate from "../../../../functions/validation";
import FormHeader from '../FormHeader';
import Input from '../../Input';
import ArrowBtn from '../../../Buttons/ArrowBtn'
import * as Form from './styles';
import { LoadingContainer, Loading } from "../../Loading";



class Business extends Component {
    state = {
        form : {
            company_name: '',
            username: '',
            email: '',
            phone: '',
            address: '',
            password: '',
            password_confirm: '',
            coporate: true
        },
        status: {
            loading: false,
            error: null
        }
    }

    handleTextChange = e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSubmit = e => {
        const { form } = this.state
        e.preventDefault()
        console.log(form.password);
        console.log(validate.password(form.password))
        console.log(this.state.form)
        if (form.password !== form.password_confirm ) { 
            console.log("password doesnt match")
            this.setState({ status: {error: "password doesnt matach"}})
        } else if ( form.password.length < 8){
            console.log("password is too short");
            this.setState({ status: { error: "password is too short" } });
        } else {
            console.log("work in progress");
            this.setState({ status: { loading: true }})
            orgAPI.signUp(form).then(res => {
                console.log(res)
                return this.setState({ status: { loading: false }})
            }).then( res => {
                this.props.history.push("/organization")
            }).catch(err => {
                console.log(err)
                this.setState({
                    status: {
                        loading: false,
                        error: err
                    }
                })
            })
        }
    }

    render() {
        const { loading, error } = this.state.status
        return (
            <div style={{ position: "relative" }}>
                {loading && (
                    <LoadingContainer>
                        <Loading> Loading...</Loading>
                    </LoadingContainer>
                )}
                <Form.Container>
                    <FormHeader
                        header="Registration"
                        description="Create a new company profile to get started"
                        primary={false}
                    />
                    <Form.Scrollable>
                        {!!error && <Form.Error>error</Form.Error>}
                        <Form.InputContainer
                            onSubmit={this.handleSubmit}
                            className="business-form-container"
                        >
                            <Form.Spacer>
                                <Input
                                    name="company_name"
                                    title="Organisation"
                                    primary={false}
                                    onChange={this.handleTextChange}
                                />
                            </Form.Spacer>
                            <Form.Spacer>
                                <Input
                                    name="username"
                                    title="Username"
                                    primary={false}
                                    onChange={this.handleTextChange}
                                    required
                                />
                            </Form.Spacer>
                            <Form.Pair>
                                <Form.PairSpace>
                                    <Input
                                        name="email"
                                        title="Contact mail Address"
                                        type="email"
                                        primary={false}
                                        onChange={this.handleTextChange}
                                        required
                                    />
                                </Form.PairSpace>
                                <Form.PairSpace>
                                    <Input
                                        name="phone"
                                        title="Phone"
                                        type="phone"
                                        primary={false}
                                        onChange={this.handleTextChange}
                                    />
                                </Form.PairSpace>
                            </Form.Pair>
                            <Form.Spacer>
                                <Input
                                    name="address"
                                    title="Address"
                                    primary={false}
                                    onChange={this.handleTextChange}
                                />
                            </Form.Spacer>
                            <Form.Pair>
                                <Form.PairSpace>
                                    <Input
                                        name="password"
                                        title="Password"
                                        type="password"
                                        primary={false}
                                        onChange={this.handleTextChange}
                                        required
                                    />
                                </Form.PairSpace>
                                <Form.PairSpace>
                                    <Input
                                        name="password_confirm"
                                        title="Confirm Password"
                                        type="password"
                                        primary={false}
                                        onChange={this.handleTextChange}
                                        required
                                    />
                                </Form.PairSpace>
                            </Form.Pair>
                            <Form.BtnContainer>
                                <ArrowBtn width="200px" pad="20px 30px">
                                    Submit
                                </ArrowBtn>
                            </Form.BtnContainer>
                        </Form.InputContainer>
                        <Form.Standards>
                            <img
                                className="registration-standards"
                                src={require("../../../../assets/svgs/auth/standards/mcafee.png")}
                                alt="mcafee ingsignia"
                            />
                            <img
                                className="registration-standards"
                                src={require("../../../../assets/svgs/auth/standards/bbb.png")}
                                alt="mcafee ingsignia"
                            />
                            <img
                                className="registration-standards"
                                src={require("../../../../assets/svgs/auth/standards/privacy.png")}
                                alt="mcafee ingsignia"
                            />
                        </Form.Standards>
                    </Form.Scrollable>
                </Form.Container>
            </div>
        );
    }
}


export default withRouter(Business);
import React from 'react'
import styled from 'styled-components'


const Container = styled.div`
    border-radius: 6px;
    background: url(${props => props.bg});
    margin: 20px;
    padding: 80px 10px 60px;
    position: relative;
    width: 300px;

    .service-cover-info {
        text-align: center;
        position: relative;
        z-index: 3;
    }
`;
const Gradient = styled.div`
    border-radius: 6px;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(360deg, #0e4189 0%, rgba(44, 44, 44, 0) 100%);
`;
const Icon = styled.img`
    margin-bottom: 20px;
`
const Title = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 125.5%;
    max-width: 150px;
    text-align: center;
    margin: 0 auto;
    color: #ffffff;
`;

const Service = ({ icon, service, bg }) => {
    return (
        <Container bg={bg} >
            <div className="service-cover-info">
                <Icon src={icon} alt="service icon" />
                <Title>{service}</Title>
            </div>
            <Gradient />
        </Container>
    )
}

export default Service

export default [
    {
        title: "Profiling",
        content: "Professional updates Profile Information on Dossr.io",
        src: require("../../../assets/svgs/HIW/1.svg"),
        alt: "profiling image"
    },
    {
        title: "Validations",
        content: "Dossr.io sends validation request to Employers and institutions ",
        src: require("../../../assets/svgs/HIW/2.svg"),
        alt: "validation image"
    },
    {
        title: "Verification",
        content: "Dossr.io sends verified credientials to Service Provider",
        src: require("../../../assets/svgs/HIW/3.svg"),
        alt: "verification image"
    }
];
import React from "react";

function Settings() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 22">
      <g fill="#717171" fillOpacity="0.51" >
        <path d="M1.444 8.06c.116.397.276.779.48 1.138l-.579.729a.599.599 0 00.045.793l.996.996a.596.596 0 00.793.045l.725-.574c.372.215.769.382 1.182.5l.108.933c.036.302.29.528.593.528h1.409a.597.597 0 00.592-.528l.104-.9a5.328 5.328 0 001.267-.512l.701.555a.599.599 0 00.793-.045l.996-.996a.596.596 0 00.045-.793l-.545-.691a5.2 5.2 0 00.522-1.246l.84-.097a.597.597 0 00.528-.593V5.893a.597.597 0 00-.528-.592l-.829-.097a5.235 5.235 0 00-.5-1.232l.51-.644a.599.599 0 00-.045-.793l-.994-.993a.596.596 0 00-.793-.045l-.625.495a5.253 5.253 0 00-1.296-.543L7.845.64a.597.597 0 00-.593-.529H5.844A.597.597 0 005.25.64l-.094.81a5.23 5.23 0 00-1.329.561l-.649-.514a.599.599 0 00-.793.045l-.996.995a.596.596 0 00-.045.793l.543.687c-.217.392-.382.81-.49 1.244l-.87.1A.597.597 0 000 5.951v1.41c0 .302.227.556.529.592l.915.106zM6.55 4.249a2.333 2.333 0 012.33 2.329 2.333 2.333 0 01-2.33 2.33 2.333 2.333 0 01-2.33-2.33 2.333 2.333 0 012.33-2.33z" />
        <path d="M20.48 8.407l-.743-.628a.588.588 0 00-.784.022l-.41.384a4.244 4.244 0 00-1.095-.354l-.116-.566a.594.594 0 00-.628-.472l-.97.082a.591.591 0 00-.54.57l-.02.575a4.258 4.258 0 00-1.045.55l-.49-.326a.59.59 0 00-.777.111l-.628.748a.588.588 0 00.021.784l.43.458a4.403 4.403 0 00-.316 1.048l-.614.125a.594.594 0 00-.472.627l.083.97c.026.298.269.531.569.54l.663.022c.12.316.278.616.47.897l-.371.56a.59.59 0 00.11.776l.744.628a.588.588 0 00.784-.022l.486-.455c.319.148.654.26.998.326l.135.665c.059.293.33.496.627.472l.97-.082a.591.591 0 00.54-.57l.022-.65c.354-.126.69-.296.998-.506l.536.354a.59.59 0 00.777-.11l.627-.744a.588.588 0 00-.02-.784l-.433-.457a4.25 4.25 0 00.34-1.053l.59-.12a.594.594 0 00.472-.628l-.082-.97a.591.591 0 00-.57-.54l-.592-.02a4.28 4.28 0 00-.493-.972l.323-.486a.587.587 0 00-.106-.779zm-3.655 5.608a1.907 1.907 0 11-.321-3.8 1.907 1.907 0 01.32 3.8zM4.837 16.624a.59.59 0 00-.53.58l-.006.598c-.005.3.217.555.514.592l.44.057c.072.267.176.521.31.762l-.282.35a.588.588 0 00.035.783l.418.427a.59.59 0 00.783.055l.352-.272c.248.146.51.262.783.342l.048.454a.59.59 0 00.58.528l.597.007c.3.005.555-.217.593-.514l.054-.43a3.55 3.55 0 00.85-.33l.33.267a.588.588 0 00.784-.036l.427-.418a.59.59 0 00.054-.783l-.252-.328a3.42 3.42 0 00.358-.826l.392-.04a.59.59 0 00.529-.581l.007-.597a.588.588 0 00-.515-.593l-.382-.049a3.552 3.552 0 00-.323-.826l.238-.293a.589.589 0 00-.035-.783l-.418-.427a.59.59 0 00-.784-.055l-.285.22a3.466 3.466 0 00-.86-.373l-.037-.37a.59.59 0 00-.58-.53l-.598-.006a.588.588 0 00-.592.514l-.047.368c-.312.08-.612.203-.89.364l-.295-.241a.589.589 0 00-.784.035l-.43.42a.59.59 0 00-.053.784l.252.326c-.146.26-.262.535-.337.826l-.413.042zm3.854-.675a1.554 1.554 0 01-.038 3.106 1.554 1.554 0 01.038-3.106z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H22V22H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

Settings.defaultProps = {
    color: "#717171"
};

export default Settings;
